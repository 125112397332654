/*
 * @Description: AKJERRT
 * @Date: 2022-07-05 15:09:03
 * @LastEditTime: 2022-07-14 10:22:20
 * @FilePath: \linkgap-front\src\views\account_statement\thead\quotaColums.js
 */
export const XYJE = [
  {
    title: '交易时间',
    dataIndex: 'dealDate',
    align: 'center',
    key: 'dealDate',
  },
  {
    title: '交易类型',
    dataIndex: 'dealType',
    align: 'center',
    key: 'dealType',
    scopedSlots: { customRender: 'dealType' },
  },
  {
    title: '交易前额度（元）',
    dataIndex: 'amount',
    align: 'center',
    key: 'amount',
    scopedSlots: {customRender: 'amount'}
  },
  {
    title: '交易额度（元）',
    dataIndex: 'dealAmount',
    align: 'left',
    key: 'dealAmount',
    scopedSlots: {customRender: 'payAmount'}
  },
  {
    title: '交易后额度（元）',
    dataIndex: 'balance',
    align: 'center',
    key: 'balance',
    scopedSlots: {customRender: 'amount'}
  },
  // {
  //   title: '上期余额',
  //   dataIndex: 'SQYE',
  //   align: 'center',
  //   key: 'SQYE',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '增加',
  //   dataIndex: 'CZ',
  //   align: 'center',
  //   key: 'CZ',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '使用',
  //   dataIndex: 'SY',
  //   align: 'center',
  //   key: 'SY',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '结余',
  //   dataIndex: 'JY',
  //   align: 'center',
  //   key: 'JY',
  //   scopedSlots: { customRender: 'amount' },
  // },
  {
    title: '关联单号',
    dataIndex: 'dealNo',
    align: 'center',
    key: 'dealNo',
    scopedSlots: {customRender: 'dealNo'}
  },
]

export const ZHZL = [
  {
    title: '项目',
    dataIndex: 'statementsType',
    key: 'statementsType',
    align: 'center',
    scopedSlots: { customRender: 'statementsType' },
  },
  {
    title: '欠款余额',
    align: 'center',
    className: 'headerYellow',
    children: [
      {
        title: '欠款',
        dataIndex: 'oweAmount',
        key: 'oweAmount',
        align: 'center',
        className: 'headerTextRed',
        scopedSlots: { customRender: 'QK' },
      },
    ],
  },
  {
    title: '可使用额度',
    align: 'center',
    className: 'headerBlue',
    children: [
      {
        title: '上期余额',
        dataIndex: 'lastMonthAmount',
        key: 'lastMonthAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '本期增加',
        dataIndex: 'addAmount',
        key: 'addAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '本期失效额度',
        dataIndex: 'efficacyAmount',
        key: 'efficacyAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '本期发货',
        dataIndex: 'shipAmount',
        key: 'shipAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '可使用结余',
        dataIndex: 'canUseAmount',
        key: 'canUseAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
    ],
  }
]

export const ZJZH = [
  {
    title: '交易时间',
    dataIndex: 'dealDate',
    align: 'center',
    key: 'dealDate',
  },
  {
    title: '交易类型',
    dataIndex: 'dealType',
    align: 'center',
    key: 'dealType',
    scopedSlots: { customRender: 'dealType' },
  },
  {
    title: '交易前金额（元）',
    dataIndex: 'amount',
    align: 'center',
    key: 'amount',
    scopedSlots: {customRender: 'amount'}
  },
  {
    title: '交易金额（元）',
    dataIndex: 'dealAmount',
    align: 'left',
    key: 'dealAmount',
    scopedSlots: {customRender: 'payAmount'}
  },
  {
    title: '交易后金额（元）',
    dataIndex: 'balance',
    align: 'center',
    key: 'balance',
    scopedSlots: {customRender: 'amount'}
  },
  // {
  //   title: '上期余额',
  //   dataIndex: 'SQYE',
  //   align: 'center',
  //   key: 'SQYE',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '充值',
  //   dataIndex: 'CZ',
  //   align: 'center',
  //   key: 'CZ',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '发货',
  //   dataIndex: 'FH',
  //   align: 'center',
  //   key: 'FH',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '结余',
  //   dataIndex: 'JY',
  //   align: 'center',
  //   key: 'JY',
  //   scopedSlots: { customRender: 'amount' },
  // },
  {
    title: '关联单号',
    dataIndex: 'dealNo',
    align: 'center',
    key: 'dealNo',
    scopedSlots: {customRender: 'dealNo'}
  },
]
export const QKMX = [
  {
    title: '交易时间',
    dataIndex: 'dealDate',
    align: 'center',
    key: 'dealDate',
  },
  {
    title: '交易类型',
    dataIndex: 'dealType',
    align: 'center',
    key: 'dealType',
    scopedSlots: { customRender: 'dealType' },
  },
  {
    title: '交易前金额（元）',
    dataIndex: 'amount',
    align: 'center',
    key: 'amount',
    scopedSlots: {customRender: 'amount'}
  },
  {
    title: '交易金额（元）',
    dataIndex: 'dealAmount',
    align: 'left',
    key: 'dealAmount',
    scopedSlots: {customRender: 'payAmount'}
  },
  {
    title: '交易后金额（元）',
    dataIndex: 'balance',
    align: 'center',
    key: 'balance',
    scopedSlots: {customRender: 'amount'}
  },
  // {
  //   title: '上期余额',
  //   dataIndex: 'SQYE',
  //   align: 'center',
  //   key: 'SQYE',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '增加',
  //   dataIndex: 'CZ',
  //   align: 'center',
  //   key: 'CZ',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '还款',
  //   dataIndex: 'FH',
  //   align: 'center',
  //   key: 'FH',
  //   scopedSlots: { customRender: 'amount' },
  // },
  // {
  //   title: '结余',
  //   dataIndex: 'JY',
  //   align: 'center',
  //   key: 'JY',
  //   scopedSlots: { customRender: 'amount' },
  // },
  {
    title: '关联单号',
    dataIndex: 'dealNo',
    align: 'center',
    key: 'dealNo',
    scopedSlots: {customRender: 'dealNo'}
  },
]